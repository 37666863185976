import React, { useContext, useState } from 'react';
import { RicosContext, RicosViewerContext } from 'ricos-context';
import { VERTICAL_EMBED_TYPE } from './viewer-loadable.js';
import classNames from 'classnames';
import { Node_Type } from 'ricos-types';
import { isSSR, getLangDir } from 'wix-rich-content-common';
import PropTypes from 'prop-types';
import { getScaleImageSrc } from 'wix-rich-content-common/libs/imageUtils';
import Tooltip from 'wix-rich-content-common/libs/Tooltip';
import { isNumber } from 'lodash';
import '@loadable/component';

var styles = {"card":"SDvfF","link":"ysvo4","container":"RJLUA","image":"o4iK6","right":"b0znH","cardLayout":"oaMYu","content":"h2dmj","title":"Ea4vh","subtitle":"qJ-mk","button":"dEji9","button_disabled":"ThLsV","buttonText":"fBBeN","slimLayout":"ajUrP","rtl":"HkiUb","ltr":"rYCog"};

var subtitle = function subtitle(info) {
  return info.leftSubtitle && /* @__PURE__ */React.createElement("div", {
    className: styles.subtitle
  }, info.leftSubtitle, info.rightSubtitle && /* @__PURE__ */React.createElement("span", null, /* @__PURE__ */React.createElement("span", {
    className: styles.right
  }, "|"), info.rightSubtitle));
};
var ellipsisTitle = function ellipsisTitle(title) {
  return title.length <= 80 ? title : title.substring(0, 80) + "...";
};
var CardContent = function CardContent(props) {
  var _classNames;
  var title = props.title,
    info = props.info,
    buttonText = props.buttonText,
    disabled = props.disabled,
    t = props.t;
  return /* @__PURE__ */React.createElement("div", {
    className: styles.content
  }, /* @__PURE__ */React.createElement("div", null, /* @__PURE__ */React.createElement("div", {
    className: styles.title
  }, ellipsisTitle(title)), info && subtitle(info)), /* @__PURE__ */React.createElement(Tooltip, {
    content: disabled ? t("VerticalEmbed_Disabled_Button_Tooltip") : null
  }, /* @__PURE__ */React.createElement("div", {
    className: classNames(styles.button, (_classNames = {}, _classNames[styles.button_disabled] = disabled, _classNames))
  }, /* @__PURE__ */React.createElement("div", {
    className: styles.buttonText
  }, buttonText))));
};
CardContent.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.object,
  buttonText: PropTypes.string.isRequired,
  disabled: PropTypes.string["boolean"],
  t: PropTypes.func.isRequired
};
var CardContent$1 = CardContent;

function _createForOfIteratorHelperLoose$1(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray$1(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray$1(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray$1(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray$1(r, a) : void 0; } }
function _arrayLikeToArray$1(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp$1 = Object.defineProperty;
var __defProps$1 = Object.defineProperties;
var __getOwnPropDescs$1 = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols$1 = Object.getOwnPropertySymbols;
var __hasOwnProp$1 = Object.prototype.hasOwnProperty;
var __propIsEnum$1 = Object.prototype.propertyIsEnumerable;
var __defNormalProp$1 = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp$1(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues$1 = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp$1.call(b, prop)) __defNormalProp$1(a, prop, b[prop]);
  if (__getOwnPropSymbols$1) {
    for (var _iterator = _createForOfIteratorHelperLoose$1(__getOwnPropSymbols$1(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum$1.call(b, prop)) __defNormalProp$1(a, prop, b[prop]);
    }
  }
  return a;
};
var __spreadProps$1 = function __spreadProps(a, b) {
  return __defProps$1(a, __getOwnPropDescs$1(b));
};
var getImageTargetHeight = function getImageTargetHeight(width, height, targetWidth) {
  var proportion = height / width;
  var targetHeight = proportion * targetWidth;
  return targetHeight;
};
var Card = function Card(props) {
  var _useContext = useContext(RicosContext),
    tabIndex = _useContext.tabIndex;
  var url = props.url,
    content = props.content,
    direction = props.direction,
    imageWidth = props.imageWidth,
    imageHeight = props.imageHeight,
    t = props.t,
    disabled = props.disabled;
  var _useState = useState(),
    imageSrc = _useState[0],
    setImageSrc = _useState[1];
  var getImageSrc = function getImageSrc(targetWidth) {
    var targetHeight = getImageTargetHeight(imageWidth, imageHeight, targetWidth);
    return getScaleImageSrc(props.imageSrc, targetWidth, targetHeight);
  };
  var setRef = function setRef(ref) {
    var _a;
    if (ref) {
      var targetWidth = (_a = ref.getBoundingClientRect()) == null ? void 0 : _a.width;
      if (imageWidth && imageHeight) {
        var imageSrc2 = getImageSrc(targetWidth);
        setImageSrc(imageSrc2);
      } else {
        setImageSrc(props.imageSrc);
      }
    }
  };
  var imageUrl = isSSR() ? imageWidth && imageHeight ? getImageSrc(185) : props.imageSrc : imageSrc;
  var imageStyle = imageUrl ? {
    backgroundImage: "url(" + imageUrl + ")"
  } : {};
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    /* @__PURE__ */
    React.createElement("a", {
      className: styles.link,
      href: disabled ? null : url,
      target: "_blank",
      ref: setRef,
      tabIndex: tabIndex
    }, /* @__PURE__ */React.createElement("div", {
      style: {
        direction: direction
      },
      className: classNames(styles[direction], styles.container, styles.cardLayout)
    }, props.imageSrc && /* @__PURE__ */React.createElement("div", {
      style: imageStyle,
      className: styles.image
    }), /* @__PURE__ */React.createElement(CardContent$1, __spreadProps$1(__spreadValues$1({}, content), {
      disabled: disabled,
      t: t
    }))))
  );
};
Card.propTypes = {
  imageSrc: PropTypes.string,
  content: PropTypes.object,
  direction: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  disabled: PropTypes["boolean"],
  t: PropTypes.func.isRequired
};
var Card$1 = Card;

var ROOT = "vertical-embed";

var convertDuration = function convertDuration(durationInMinutes, t) {
  if (!isNumber(durationInMinutes)) return "";
  if (durationInMinutes < 60) {
    return t("VerticalEmbed_Units_Minute", {
      minutes: durationInMinutes
    });
  }
  var hours = durationInMinutes / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  var durationInHours = rminutes === 0 ? t("VerticalEmbed_Units_Hour", {
    hours: rhours
  }) : t("VerticalEmbed_Units_Hour", {
    hours: rhours
  }) + " " + t("VerticalEmbed_Units_Minute", {
    minutes: rminutes
  });
  return durationInHours;
};

var getBookingContent = function getBookingContent(_ref, t) {
  var name = _ref.name,
    durations = _ref.bookingData.durations;
  return {
    title: name,
    info: {
      leftSubtitle: durations && convertDuration(durations, t)
    },
    buttonText: t("VerticalEmbed_Bookings_Button")
  };
};
var getEventContent = function getEventContent(_ref2, t) {
  var name = _ref2.name,
    _ref2$eventData = _ref2.eventData,
    scheduling = _ref2$eventData.scheduling,
    location = _ref2$eventData.location;
  return {
    title: name,
    info: {
      leftSubtitle: scheduling,
      rightSubtitle: location
    },
    buttonText: t("VerticalEmbed_Events_Button")
  };
};
var getProductContent = function getProductContent(_ref3, t) {
  var name = _ref3.name;
  return {
    title: name,
    buttonText: t("VerticalEmbed_Products_Button")
  };
};
var contentTypeMapGetter = {
  BOOKING: getBookingContent,
  EVENT: getEventContent,
  PRODUCT: getProductContent
};
var verticalsDataBuilder = function verticalsDataBuilder(componentData, t, changeBaseUrl) {
  if (changeBaseUrl === void 0) {
    changeBaseUrl = function changeBaseUrl(x) {
      return x;
    };
  }
  var url = componentData.url,
    image = componentData.image;
  return {
    url: changeBaseUrl(url),
    imageSrc: image.src.url,
    imageWidth: image.width,
    imageHeight: image.height,
    content: contentTypeMapGetter[componentData.type](componentData, t)
  };
};

function _createForOfIteratorHelperLoose(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) {
    for (var _iterator = _createForOfIteratorHelperLoose(__getOwnPropSymbols(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
    }
  }
  return a;
};
var __spreadProps = function __spreadProps(a, b) {
  return __defProps(a, __getOwnPropDescs(b));
};
var VerticalEmbedViewer = function VerticalEmbedViewer(_ref) {
  var _classnames;
  var settings = _ref.settings,
    componentData = _ref.componentData,
    helpers = _ref.helpers,
    nodeId = _ref.nodeId;
  var _useContext = useContext(RicosContext),
    t = _useContext.t,
    locale = _useContext.locale;
  var onClick = function onClick() {
    var _a;
    return (_a = helpers.onViewerAction) == null ? void 0 : _a.call(helpers, VERTICAL_EMBED_TYPE, "Click", componentData.type, nodeId, {
      type: Node_Type.APP_EMBED,
      appEmbedData: componentData
    });
  };
  var props = verticalsDataBuilder(componentData, t, settings.changeBaseUrl);
  var direction = getLangDir(locale);
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    /* @__PURE__ */
    React.createElement("div", {
      className: classNames(styles.card, (_classnames = {}, _classnames[styles.slimLayout] = settings.slimLayout, _classnames)),
      "data-hook": ROOT,
      onClick: onClick
    }, /* @__PURE__ */React.createElement(Card$1, __spreadProps(__spreadValues({}, props), {
      t: t,
      direction: direction,
      disabled: settings.disabled
    })))
  );
};

var VerticalEmbed = function VerticalEmbed(_ref) {
  var node = _ref.node,
    nodeIndex = _ref.nodeIndex,
    NodeViewContainer = _ref.NodeViewContainer;
  var _useContext = useContext(RicosViewerContext),
    helpers = _useContext.helpers,
    config = _useContext.config;
  return /* @__PURE__ */React.createElement(NodeViewContainer, {
    node: node,
    nodeIndex: nodeIndex
  }, /* @__PURE__ */React.createElement(VerticalEmbedViewer, {
    nodeId: node.id,
    settings: config[VERTICAL_EMBED_TYPE] || {},
    componentData: node.appEmbedData,
    helpers: helpers
  }));
};

export { VerticalEmbed as default };

import 'wix-rich-content-plugin-vertical-embed/dist/loadable/viewer/es/styles.viewer.min.global.css'